<template>
  <div class="list">
    <div
      v-for="(item, index) in content"
      :key="index"
      class="item flex"
      :class="{ 'item-bordernone': index == 0, LeftBorders: index == MoveItem }"
      @mousemove="MoveItem = index"
      @click="GOdetali(item)"
    >
      <div class="item-ctime center-noflex">
        <div
          style="font-size: 22px; color: rgb(0, 118, 254); margin-bottom: 2px"
        >
          {{ item.today }}
        </div>
        <div style="color: #676767l;white-space: nowrap;">{{ item.year }}</div>
      </div>
      <div style="width:100%;margin: auto 0px;">
        <div
          style="color: rgb(0, 118, 254); display: inline-block;font-size: 18px;"
        >
          {{ item.title }}
        </div>
        <!-- <div style="max-width: 90%" v-html="item.content"></div> -->
        <div class="end" style="display: inline-block;float: right;">
          <div style="color: rgb(0, 118, 254)">更多 ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["content"],

  data() {
    return {
      MoveItem: 0,
      list: [
        {
          id: 1,
          title: "百舸争流，奋楫者先 江苏省文交所影视版权交易中心...",
          content:
            "影视版权交易中心（以下简称中心）是江苏省文化产权交易所发挥自身优势，在文化+互联网+金融方面做的 新尝试，中心...",
          ctime: "2021-01",
          hao: "20",
        },
        {
          id: 1,
          title: "百舸争流，奋楫者先 江苏省文交所影视版权交易中心...",
          content:
            "影视版权交易中心（以下简称中心）是江苏省文化产权交易所发挥自身优势，在文化+互联网+金融方面做的 新尝试，中心...",
          ctime: "2021-01",
          hao: "20",
        },
        {
          id: 1,
          title: "百舸争流，奋楫者先 江苏省文交所影视版权交易中心...",
          content:
            "影视版权交易中心（以下简称中心）是江苏省文化产权交易所发挥自身优势，在文化+互联网+金融方面做的 新尝试，中心...",
          ctime: "2021-01",
          hao: "20",
        },
        {
          id: 1,
          title: "百舸争流，奋楫者先 江苏省文交所影视版权交易中心...",
          content:
            "影视版权交易中心（以下简称中心）是江苏省文化产权交易所发挥自身优势，在文化+互联网+金融方面做的 新尝试，中心...",
          ctime: "2021-01",
          hao: "20",
        },
      ],
    };
  },
  methods: {
    GOdetali(e) {
      this.$router.push("/index/guide/detali?id=" + e.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  white-space: normal;
  padding: 23px 18px 0px 48px;
  background: #ffffff;
  width: 100%;
  .item {
    border-top: 1px solid #dfdfdf;
    border-left: 3px solid #ffffff;
    transition: all 0.5s;
    padding: 4px 27px 14px 25px;
    cursor: pointer;
    .item-ctime {
      text-align: center;
      margin-right: 30px;
    }
  }
}
.item-bordernone {
  border-top: none !important;
}
.LeftBorders {
  border-left: 3px solid rgb(0, 118, 254) !important;
}
</style>